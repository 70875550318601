import * as React from "react";

export const SvgSun = (props) => (
  <svg
    width={props.width || "90px"}
    fill="none"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.455 9.09H0v1.82h5.455zm1.972-2.945L5.5 4.218 4.218 5.5l1.927 1.927zM10.91 0H9.091v5.455h1.818zm4.873 5.5L14.5 4.218l-1.927 1.927 1.282 1.282zm-1.237 3.59v1.82H20V9.09zM10 7.274A2.724 2.724 0 0 0 7.273 10 2.724 2.724 0 0 0 10 12.727 2.724 2.724 0 0 0 12.727 10 2.724 2.724 0 0 0 10 7.273zm2.573 6.582 1.927 1.927 1.282-1.282-1.927-1.927zm-8.355.645L5.5 15.782l1.927-1.927-1.282-1.282zM9.091 20h1.818v-5.455H9.091z"
      fill="currentColor"
    />
  </svg>
);
